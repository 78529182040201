import axios from '@/libs/axios'
import endpoints from '@/http/endpoints'

export default {
  async getListByFilter(planId) {
    const result = await axios.get(endpoints.membershipPlansDetails.Controller.getListByFilter(planId))
    return result
  },
  async findById(id) {
    const result = await axios.post(endpoints.membershipPlansDetails.Controller.findById(id))
    return result
  },
  async create(data) {
    const result = await axios.post(endpoints.membershipPlansDetails.Controller.create(), data)
    return result
  },
  async update(data) {
    const result = await axios.put(endpoints.membershipPlansDetails.Controller.update(), data)
    return result
  },
  async delete(id) {
    const result = await axios.delete(endpoints.membershipPlansDetails.Controller.delete(id))
    return result
  },
  // #region add
  async addMemberShipPlanDetails(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.membershipManagement.Controller.addPlanDetail(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  async addMemberShipPlanDetailsAsBulk(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.membershipManagement.Controller.addPlanDetailAsBulk(), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion

  // #region delete
  async deleteMemberShipPlanDetail(data) {
    const promise = new Promise((resolve, reject) => {
      axios.delete(endpoints.membershipManagement.Controller.deletePlanDetail(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion

  // #region Edit
  async editMemberShipPlanDetail(data, id) {
    const promise = new Promise((resolve, reject) => {
      axios.put(endpoints.membershipManagement.Controller.editPlanDetail(id), data)
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
  },
  // #endregion

  // #region get
  async getMemberShipPlanDetails() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getPlanDetails())
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },

  async getMemberShipPlanDetailByPlanId(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getPlanDetailsByPlanId(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },

  async getMemberShipPlanDetailById(data) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.membershipManagement.Controller.getPlanDetail(data))
        .then(response => {
          resolve({ data: response.data })
        })
        .catch(error => reject(error))
    })
    promise.then(result => result, error => error)
    return promise
  },
  // #endregion
}
