<template>
  <dx-util-popup
    ref="subscribedPlanDetailsPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="false"
    height="auto"
    width="40vw"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="subscribedPlanDetailsPopupShown"
    @hidden="subscribedPlanDetailsPopupHidden"
  >
    <h2 class="text-center mb-2">
      {{ planName }} Details</h2>
    <div class="d-flex">
      <div class="col-6 text-center m-auto">
        <p-lazy-img width="400px" height="" :src="require('@/assets/images/undraw/completed_tasks.svg')" />
      </div>
      <div class="col-5">
        <span v-for="item in features" :key="item.id">
          <div :class="item.isHighlight ? 'text-bold text-warning font-weight-bold p-half m-half rounded' : 'p-half m-half'">
            {{ item.feature }}
          </div>
        </span>
      </div>
    </div>
  </dx-util-popup>
</template>

<script>
import membershipPlanDetailService from '@/http/requests/membership/plandetail'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    planId: {
      type: Number,
      required: true,
    },
    planName: {
      type: String,
      default: 'Plan',
    },
  },
  data() {
    return {
      popupTitle: '',
      features: [],
    }
  },
  computed: {
    subscribedPlanDetailsPopup() {
      return this.$refs.subscribedPlanDetailsPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.subscribedPlanDetailsPopup.show()
        this.getSubscribedPlanDetails()
      },
    },
  },
  methods: {
    subscribedPlanDetailsPopupShown() {
      this.popupTitle = 'Subscribed Plan Details'
    },
    subscribedPlanDetailsPopupHidden() {
    },
    closePopup() {
      this.subscribedPlanDetailsPopup.hide()
    },
    async getSubscribedPlanDetails() {
      const result = await membershipPlanDetailService.getListByFilter(this.planId) //
      this.features = result?.data?.body.sort((a, b) => b.isHighlight - a.isHighlight)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
